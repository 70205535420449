.team-box {
    margin: auto;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 35px;
    // border: 3px solid $black;
    padding-left: 25px;
    padding-right: 25px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;

    @include breakpoint(md) {
        margin-right: 5%;
        margin-left: 5%;
    }
}

.team-header {
    color: $brown;
    font-size: 36px;
    font-weight: 700;
    text-align: center;
}

.team-list {
    display: grid;
    grid-template-columns: auto auto;
    list-style-type: none;

}

.team-right {
    grid-column: 2;
    grid-row-start: 2;
    text-align: right;
}

.team-left {
    grid-column: 1;
}

.team-left,
.team-right {
    font-size: 22px;

    @include breakpoint(sm) {
        font-size: 16px;
        line-height: 90%;

        li {
            padding-top: 7px;
        }
    }

    li {
        padding-top: 15px;
    }
}

.team-list-header {
    margin: 15px;
    font-size: 22px;
    font-weight: 500;
    grid-column: span 2;
    text-align: center;
}

.person-card {
    // max-width: 280px;
    // margin: 30px 0px 30px 0px;
    // display: flex;
    padding: 0px 0px 20px 0px;
    // justify-content: center;
    // flex-direction: column;
    // align-items: center;
    // box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;

    &__picture {
        img {
            max-width: 280px;
        }
    }


    &__name {
        font-weight: 600;
        font-size: 26px;
        line-height: 135%;
        color: $brown;
    }



    &__info {
        display: flex;
        flex-direction: column;
    }
}

.person-container {
    margin: auto;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 35px;
    // border: 3px solid $black;
    padding-left: 25px;
    padding-right: 25px;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;

    @include breakpoint(md) {
        margin-right: 5%;
        margin-left: 5%;
    }

}