@import "font";
@import "color";
@import "mixin";

.header {
  background-color: $black;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);


  @include breakpoint(sm) {
    display: block;
  }
}

.wrapperLogoSvg {
  max-width: 200px;

  @include breakpoint(sm) {
    display: inline;
    align-items: center;
  }
}

.header-logo {
  @include breakpoint(sm) {
    display: inline;
    width: 50px;
  }
}

.logo {
  max-width: 100%;
  height: auto;

  @include breakpoint(sm) {
    width: 150px;
    padding-left: 5px
  }
}

.header-svg {
  justify-content: flex-end;
  color: $brown;
}

.menu-svg {
  width: 50px;
  float: right;
  padding-top: 5px;
  padding-right: 5px;
  display: none;

  @include breakpoint(sm) {
    display: block;
  }
}


.header-element {
  font-size: $header-element;

  @include breakpoint(sm) {
    font-size: $header-element-mobile;
  }

  color: $brown;

  ul {
    @include breakpoint(sm) {
      text-align: center;
      padding-bottom: 0.5px;
    }

    @include breakpoint(md) {
      margin: 0px;
      padding-inline-start: 0px;

    }

    padding: 0;
    width: 100%;
    height: auto;
    list-style-type: none;

    color: $brown;



    li:first-child {
      @include breakpoint(sm) {
        margin-top: 10px;
      }
    }

    li {

      @include breakpoint(md) {
        margin: 25px;
      }

      @include breakpoint(sm) {
        margin: 25px;
      }


      margin: 40px;
      text-decoration: none;

      a,
      a:focus,
      a:active {
        @include breakpoint(sm) {
          padding: 12px;
        }

        text-decoration: none;
        color: inherit;
      }

      a:hover {
        color: $brown-darker;
      }
    }
  }
}

.listitmes {
  display: flex;

  @include breakpoint(sm) {
    display: none;
  }
}

.visable {
  @include breakpoint(sm) {
    display: block;
  }
}

.social {
  @include breakpoint(sm) {
    display: none;
  }
}

.only-mobile {
  display: none;

  @include breakpoint(sm) {
    display: block;
  }
}