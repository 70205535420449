.start-picture {
  position: relative;

  img {
    width: 90%;
    height: auto;
    margin: auto;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

    @include breakpoint(lg) {
      width: 100%;
    }
  }
}

.start-label {
  position: absolute;
  background: rgba(65, 184, 74, 0.8);
  border-radius: 5px;
  transform: rotate(30deg);
  right: 0px;
  top: 0px;
  margin: 2%;
  padding: 5px 20px;
  text-align: center;
  font-size: $h2;

  @include breakpoint(lg) {
    font-size: 18px;
  }
}

.img-wrapper {
  max-width: 500px;
}
.img-angebot {
  max-width: 100%;
  height: auto;
}
