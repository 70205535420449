.preise-box {
    margin: auto;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5%;
    margin-bottom: 5%;
    border: 3px solid $black;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);

    @include breakpoint(md) {
        margin-right: 5%;
        margin-left: 5%;
        font-size: 24px;
    }

    @include breakpoint(sm) {
        font-size: 16px;
    }

    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-template-rows: auto auto auto auto auto 50px;
    font-size: 32px;
    overflow: auto;
    white-space: nowrap;


}

.preise-header {
    background-color: $black;
    color: $brown;

}

.preise-center {
    text-align: center;
}

.preise-left {
    padding-left: 20px;
}

.preise-extracost {
    grid-column: 3;
    font-size: 15px;

}

.preise-inhalt {
    padding-top: 20px;
    padding-bottom: 20px;
}

.preise-top-border {
    border-top: 3px solid $black;
}