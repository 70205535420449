@import "color";
@import "header";
@import "font";
@import "headlines";
@import "welcome-elements";
@import "news";
@import "team";
@import "footer";
@import "start";
@import "fontstyle";
@import "swiper";
@import "sportangebote";
@import "preise";
@import "impressung.scss";
@import "mason.scss";
@import "modal.scss";

body {
    margin: 0;
    font-family: 'Work Sans';
}

p {
    margin: 0;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased !important;
    -moz-font-smoothing: antialiased !important;
    text-rendering: optimizelegibility !important;
    letter-spacing: .03em;
}

div {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased !important;
    -moz-font-smoothing: antialiased !important;
    text-rendering: optimizelegibility !important;
    letter-spacing: .03em;
}

li {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased !important;
    -moz-font-smoothing: antialiased !important;
    text-rendering: optimizelegibility !important;
    letter-spacing: .03em;
}