// .grid-item {
//     max-width: 500px;
//     float: left;

//     img {
//         width: 100%;
//     }
// }

// .grid {}

.mason-grid-container {
    max-width: 1200px;
    margin: 0 auto;
}

.mason-grid:after {
    content: '';
    display: block;
    clear: both;
}

.mason-grid-sizer,
.mason-grid-item {
    width: 33.333%;
}

.mason-grid-item {
    float: left;

    img {
        padding: 10px;
    }
}

.mason-grid-item img {
    display: block;
    max-width: 100%;
}

.picture-wrapper {
    justify-content: center;
    display: flex;
    flex-wrap: wrap;

    &__element {
        padding: 15px;

        img {
            max-width: 420px;
        }
    }
}