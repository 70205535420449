@import "font";
@import "color";

.footer-box {
    color: $white;
    padding-left: 10%;
    padding-right: 10%;
    width: 80%;
    padding-top: 30px;
    display: grid;
    grid-template-rows: auto auto;
    background-color: black;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);

    @include breakpoint(sm) {
        display: block;
    }

}

.footer-left {
    grid-column-start: col-start first;

    div:nth-child(4) {
        margin-bottom: 10px;
    }

    div:nth-child(6) {
        margin-bottom: 10px;
    }

}


.footer-right {

    grid-column-start: col-start 2;

    div:nth-child(2) {
        margin-bottom: 10px;
    }

    div:nth-child(6) {
        margin-bottom: 10px;
    }

    @include breakpoint(sm) {
        margin-top: 15px;
    }
}

.footer-header {
    margin-bottom: 10px;
    font-size: $footer-header;
    font-weight: 600;
}

.footer-bottom {
    a {
        text-decoration: none;
        color: white;
    }

    :hover {
        color: #d9d9d9
    }

    color: white;
    margin-top: 15px;
    background-color: #080808;
    grid-column: span 3 //clip: auto;


}

.phone-number {
    a {
        text-decoration: none;
        color: white;
    }

    :hover {
        color: #d9d9d9
    }
}

.email {
    a {
        text-decoration: none;
        color: white;


    }

    :hover {
        color: #d9d9d9
    }
}

.Servicezeiten {
    font-weight: $bold;
}