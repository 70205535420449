@import "font";
@import "color";
@import "mixin";

.modal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border: 1px solid $brown-darker;
    box-shadow: 0 4px 8px $brown-darker(0, 0, 0, 0.1);
    z-index: 20;
    position: fixed;
    border-radius: 30px;
}

