// .welcome-header {
//     text-align: center;
// }

// .welcome-box {
//     display: inline-grid;
//     grid-template-columns: 200px auto;
//     border: 3px solid $black;
//     // text-align: center;
//     margin-left: 10%;
//     margin-right: 10%;

//     line-height: 170%;
//     padding: 5px;
// }

// .welcome-picture {

//      img {
//         max-width: 100%;
//         height: auto;
//     }
// }

// .welcome-text{
//     padding-left: 10px;
//     display: inline;
//     font-size: 20px;
//     @include breakpoint(md){
//         font-size: 15px;
//     }
// }


.welcome-header {
    text-align: center;
}

.welcome-box {
    display: inline-grid;
    grid-template-columns: 200px auto;


    @include breakpoint(md) {
        grid-template-columns: 150px auto;
        margin-right: 5%;
        margin-left: 5%;
    }

    @include breakpoint(sm) {
        grid-template-columns: auto;
    }

    // text-align: center;
    margin-left: 10%;
    margin-right: 10%;

    line-height: 30px;

}

.welcome-picture {

    margin-top: 60px;

    img {
        max-width: 100%;
        height: auto;
        box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
    }

    @include breakpoint(sm) {
        display: none;
    }
}

.welcome-text {
    // border: 3px solid $black;
    padding: 10px;

    display: inline;
    font-size: $text-normal;
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;


    @include breakpoint(md) {
        font-size: $text-small;
    }
}

.welcome-italic {
    font-style: italic;
    font-size: 24px;

    @include breakpoint(md) {
        font-size: 20px;

    }

}