@import "color";

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}



// .swiper-all {
//     margin-top: 25px;
//     height: auto;
// }

// .swiper {
//     width: 100%;
//     height: 100%;
// }

.swiper-button-next,
.swiper-button-prev {
    color: $brown !important;
}

// .swiper-slide {
//     text-align: center;
//     font-size: 18px;
//     background: #fff;

//     /* Center slide text vertically */
//     display: -webkit-box;
//     display: -ms-flexbox;
//     display: -webkit-flex;
//     display: flex;
//     -webkit-box-pack: center;
//     -ms-flex-pack: center;
//     -webkit-justify-content: center;
//     justify-content: center;
//     -webkit-box-align: center;
//     -ms-flex-align: center;
//     -webkit-align-items: center;
//     align-items: center;


// }

// .swiper-name {
//     font-weight: 600;
//     font-size: 26px;
//     line-height: 135%;
//     color: $brown;
// }


// .swiper-slide img {
//     display: block;
//     width: 280px;
//     height: 100%;
//     object-fit: cover;
// }