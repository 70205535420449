h1{
    font-size: $h1;
    font-weight: $bold;
    text-align: center;
}

h2{
    font-size: $h2;
    font-weight: $bold;
    text-align: center;
    margin: 0;
}