$breakpoints: (
    sm:576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);


@mixin breakpoint($name) {
    @if not map-has-key($breakpoints, $name) {
        @warn "Warning: `#{$name}` is not a valid breakpoint name.";
    }

    @else {
        @media (max-width: map-get($breakpoints, $name)) {
            @content;
        }

    }
}