$text-small: 15px;
$text-normal: 22px;

$h1: 40px;
$h2: 36px;
$h3: 32px;
$h4: 28px;
$names: 35px;

$bold: 700;
$bolder: 800;

$header-element: 20px;
$header-element-mobile: 25px;

$footer-header: 22px;
$footer-element: 15px;