.impressun-box {
    margin: auto;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 35px;
    margin-bottom: 35px;
    border: 3px solid $black;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 25px;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);

    @include breakpoint(md) {
        margin-right: 5%;
        margin-left: 5%;
    }
}

.impressun-header {
    margin-top: 25px;

    div:not(:first-child) {
        margin-top: 25px;
    }
}

.impressun-text {
    margin-top: 10px;
    font-size: 12px;
}

.impressun-header {
    font-weight: bold;
    font-size: 15px;
}

.impressun-box-header {
    margin-top: 10px;
    font-weight: bold;
    font-size: 20px;
}