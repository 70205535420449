@import "font";
@import "color";


$breakpoints: (
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
);


@mixin breakpoint($name) {
    @if not map-has-key($breakpoints, $name) {
        @warn "Warning: `#{$name}` is not a valid breakpoint name.";
    }

    @else {
        @media (max-width: map-get($breakpoints, $name)) {
            @content;
        }

    }
}

.news-box {
    margin: auto;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 35px;
    font-size: $text-normal;


    @include breakpoint(md) {
        margin-right: 5%;
        margin-left: 5%;
        font-size: $text-small;
    }
}

.news-headline {
    margin: 0;
    color: $brown;
    background-color: black;
}

.news-all {
    padding: 5px;
    border: 3px solid $black;
    font-size: 20px;

    @include breakpoint(md) {
        font-size: 15px;
    }



}

.news-titel {
    list-style: none;
    padding-bottom: 5px;
}

.news-list-header {
    font-weight: bold;
    margin-left: -20px;
}

.news-btn {

    max-width: 200px;
    margin: auto;
    border: 3px solid $brown;
    text-align: center;
    border-radius: 5px;
}

.news-header {
    font-weight: bold;
    margin-bottom: 15px;
}

// Layout
.row {
    display: flex;

    .col {
        flex: 1;

        &:last-child {
            margin-left: 1em;
        }
    }
}

/* Accordion styles */
.news-box {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
}

.tab {
    width: 100%;
    color: $brown;
    overflow: hidden;

    &-label {
        display: flex;
        justify-content: space-between;
        padding: 1em;
        background: black;
        font-weight: bolder;
        font-size: 26px;
        cursor: pointer;

        /* Icon */
        &:hover {
            background: darken(black, 10%);
        }

        &::after {
            content: "\276F";
            width: 1em;
            height: 1em;
            text-align: center;
            transition: all .35s;
        }
    }

    &-content {
        max-height: 0;
        padding: 0 1em;
        color: black;
        background: white;
        transition: all .35s;
    }

    &-close {
        display: flex;
        justify-content: flex-end;
        padding: 1em;
        font-size: 0.75em;
        background: black;
        cursor: pointer;

        &:hover {
            background: darken(black, 10%);
        }
    }

    input {
        position: absolute;
        opacity: 0;
        z-index: -1;
    }
}

// :checked
input:checked {
    +.tab-label {
        background: darken(black, 10%);

        &::after {

            transform: rotate(90deg);
        }
    }

    ~.tab-content {
        max-height: 100vh;
        padding: 1em;
    }
}

.list-container-news {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 40px;
    gap: 40px;

    &_item {
        // box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
        // border: 3px solid $black;
        flex: 40%;
        margin-bottom: 30px;

        @include breakpoint(md) {
            flex: 100%
        }
    }

    &_card {
        max-width: 520px;
        margin: auto;
        border-radius: 30px;
        // border: 3px solid $black;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    }

    &_header {
        text-align: left;
        font-size: 32px;
        margin-top: 5px;
        margin-bottom: 5px;
        color: $brown;
        white-space: nowrap;
        font-weight: 600;
        padding-left: 15px;
        padding-right: 15px;

        @include breakpoint(sm) {
            white-space: pre-wrap;

        }

    }

    &_text {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 0px;
        padding-bottom: 20px;
    }

    &_picture {
        max-width: 520px;

        img {
            width: 100%;
            border-radius: 27px 27px 0px 0px;
        }

    }


}

.header-social-wrapper {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}