// .swiper-sportangebote {
//     margin-top: 40px;
//     margin-bottom: 40px;
//     margin-right: 8%;
//     margin-left: 8%;


//     @include breakpoint(md) {
//         margin-right: 5%;
//         margin-left: 5%;
//     }
// }

// .bild-text-wrapper {
//     display: grid;
//     text-align: left;
//     grid-template-columns: auto auto;
//     grid-template-rows: auto auto;
//     column-gap: 25px;


//     @include breakpoint(md) {
//         display: block;
//         column-gap: unset;
//     }

//     @include breakpoint(sm) {}

// }


// .sportangebote-header {
//     text-align: left;
//     font-size: 32px;
//     margin-bottom: 30px;
//     grid-column: span 2;
//     color: $brown;
// }

// .sportangebot-picture {
//     grid-column: 1;
//     width: auto;
//     height: 100%;

// }

// .sport-arrow {
//     @include breakpoint(sm) {
//         display: none;
//     }
// }

// .sportangebot-text {
//     width: 500px;
//     font-size: 22px;
//     line-height: 135%;
//     padding-top: 15px;
//     max-width: unset;


//     @include breakpoint(sm) {
//         max-width: fit-content;
//         font-size: 16px;
//     }
// }

// .slide-sportangebote img {
//     height: 100%;
//     width: 100%;
//     object-fit: contain;


// }

// .slide-sportangebote {
//     @include breakpoint(sm) {
//         display: block;

//     }
// }

.list-container {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 40px;
    gap: 40px;

    &_item {
        // box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.5);
        // border: 3px solid $black;
        flex: 40%;
        margin-bottom: 30px;

        @include breakpoint(md) {
            flex: 100%
        }
    }

    &_card {
        max-width: 520px;
        margin: auto;
        border-radius: 30px;
        // border: 3px solid $black;
        box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
    }

    &_header {
        text-align: left;
        font-size: 32px;
        margin-top: 5px;
        margin-bottom: 5px;
        color: $brown;
        white-space: nowrap;
        font-weight: 600;
        padding-left: 15px;
        padding-right: 15px;

        @include breakpoint(sm) {
            white-space: pre-wrap;

        }

    }

    &_subtitle {
        text-align: left;
        font-size: 15px;
        color: $grey;
        white-space: nowrap;
        font-weight: 500;
        padding: 15px 15px 0px 15px;

        @include breakpoint(sm) {
            white-space: pre-wrap;

        }

    }

    &_text {
        padding-left: 15px;
        padding-right: 15px;
        margin-bottom: 0px;
        padding-bottom: 20px;
    }

    &_picture {
        max-width: 520px;

        img {
            width: 100%;
            border-radius: 27px 27px 0px 0px;
        }

    }


}